<template>
  <div class="dg-reset-password">
    <form
      ref="form"
      @submit.prevent="onSubmit"
      @keydown.enter.prevent="onSubmit"
    >
      <ValidationObserver ref="validation">
        <ValidationProvider
          vid="form.password"
          rules="required"
          v-slot="{ errors }"
        >
          <DgPortalInput
            v-model="form.password"
            ref="PASSWORD"
            name="PASSWORD"
            type="password"
            :placeholder="$t('PASSWORD')"
            autofocus
            variant="outline"
            prepend-icon="Lock"
            prepend-icon-color="#4B4B4E"
            :error="errors[0]"
          />
        </ValidationProvider>
        <ValidationProvider
          :rules="{
            required: true,
            confirm: {
              target: '@form.password',
            },
          }"
          v-slot="{ errors }"
        >
          <DgPortalInput
            v-model="form.confirmPassword"
            ref="CONFIRM_PASSWORD"
            name="CONFIRM_PASSWORD"
            type="password"
            :placeholder="$t('CONFIRM_PASSWORD')"
            autofocus
            variant="outline"
            prepend-icon="Lock"
            prepend-icon-color="#4B4B4E"
            :error="errors[0]"
          />
        </ValidationProvider>
        <DgPortalButton
          type="submit"
          :portalColor="portalSettings.portalColor"
          :label="$t('RESET_YOUR_PASSWORD')"
          :loading="loading"
          size="sm"
          full-width
        />
      </ValidationObserver>
    </form>
    <div
      class="form-auth-reset-password__additional-info"
      :style="additionaInfoStyle"
    >
      <p class="form-auth-reset-password__additional-info__text">
        <span>{{ $t("REQUEST_ANOTHER_TOKEN") }}</span>
        <DgLink
          tag="router-link"
          to="/forgot-password"
          label="FORGOT_PASSWORD"
        />
      </p>
      <p class="form-auth-reset-password__additional-info__text">
        <span>{{ $t("HAVE_ALREADY_ACCOUNT") }}</span>
        <DgLink tag="router-link" to="/" label="SIGN_IN" />
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { fetchChangePassword } from "@/service/auth";
import { DgPortalInput, DgPortalButton, DgLink } from "@engenharia/dashgoo-ui";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "PageAuthResetPassword",
  components: {
    DgLink,
    DgPortalInput,
    DgPortalButton,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    form: {
      password: "",
      confirmPassword: "",
    },
    loading: false,
    additionaInfoStyle: null,
    root: null,
  }),
  computed: {
    ...mapState({
      portalSettings: (state) => state.portal?.settings,
    }),
    ticket() {
      return this.$route.params.ticket;
    },
    formPayload() {
      return {
        password: this.form.password,
        ticket: this.ticket,
      };
    },
  },
  mounted() {
    const color =
      this.portalSettings?.portalColor === "#FFFFFF"
        ? "#000000"
        : this.portalSettings.portalColor;
    this.form.password = "";
    this.form.confirmPassword = "";
    this.additionaInfoStyle = {
      "--reset-password-link-color": color,
    };
    this.root = document.documentElement;
    this.root.style.setProperty("--swal-alert-confirm-button-color", color);
  },
  methods: {
    async onSubmit() {
      const formIsValid = this.$refs.validation
        ? await this.$refs.validation.validate()
        : true;

      if (!formIsValid) return;

      try {
        this.loading = true;
        const form = { ...this.formPayload };

        await fetchChangePassword(form);

        this.$notifySuccess({
          title: "SUCCESS",
          text: "PASSWORD_SUCCESSFULLY_CHANGED",
          callback: () => {
            this.$router.push({ path: `/${this.$route.params.id}` });
          },
        });
      } catch (error) {
        this.$notifyFailed({
          title: "ERROR_OPS_NOT_FOUND",
          text: "CHANGE_PASSWORD_TOKEN_INVALID",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.swal2-container.swal2-center > .swal2-popup {
  border-radius: 40px;
}
.form-auth-reset-password__additional-info {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #525252;
  &__text {
    display: flex;
    grid-gap: 4px;
    justify-content: center;
  }
  & .dg-link {
    font-family: inherit;
    font-style: inherit;
    font-weight: 600;
    font-size: inherit;
    line-height: inherit;
    text-decoration-line: underline;
    color: var(--reset-password-link-color) !important;
  }
}
</style>
